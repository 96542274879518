import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigatorService } from '../_services/navigator.service';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  signupUrl: string;
  portalUrl: string;
  salesEmail = 'sales@hexa.app';
  showLightSpeed = false;
  activeLanguage: string;
  noDot: { key: string; code: string; value: Promise<string> }[] = [];

  constructor(
    public navigator: NavigatorService,
    public translator: TranslatorService,
    public route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.translator.activeLanguage.listen((lang) => {
      if (lang) {
        this.activeLanguage = lang;
      }
    });

    this.route.url.subscribe((url) => {
      if (url.length && url[0].path.toLowerCase().trim() === 'lightspeed') {
        this.showLightSpeed = true;
      }
    });

    await this.autoplay();
  }

  async autoplay() {
    let video = document.querySelector('video[muted][autoplay]') as any;

    try {
      video.muted = true;
      await video.play();
    } catch (err) {}
  }

  stripDot(key: string): Promise<string> {
    if (this.noDot.some((v) => v.key === key && v.code === this.activeLanguage)) {
      return this.noDot.find((v) => v.key === key && v.code === this.activeLanguage).value;
    }

    return this.translator.translate(key).then((s) => {
      const noDot = s.replace('.', '');
      this.noDot.push({ key, code: this.activeLanguage, value: Promise.resolve(noDot) });
      return noDot;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslatorService } from 'src/app/_services/translator-service';
import { ConfigService } from '../_services/config.service';

@Component({
  selector: 'app-start-now',
  templateUrl: './start-now.component.html',
  styleUrls: ['./start-now.component.scss']
})
export class StartNowComponent implements OnInit {

  public portalUrl = '';
  public signupUrl = '';
  public email = 'Support@hexa.app';

  constructor(private configService: ConfigService,public translator: TranslatorService) { }

  ngOnInit(): void {
    this.translator.activeLanguage.listen(lang =>{
      if(lang){
        this.setUrl();
      }
    });

    this.setUrl();
    this.signupUrl = this.configService.config.signup.baseUrl;
  }

  setUrl(){
    console.log(this.translator.activeLanguage.value);

    var lang = this.translator.activeLanguage.value == 'fr' ? '&culture=fr' : '';
    var returnUrl = 'returnUrl=' + encodeURIComponent(this.configService.config.portal.baseUrl);
    var url = this.configService.config.identity.baseUrl + '/Account/Login?' + returnUrl + lang;

    this.portalUrl = url;
  }

  getSignupUrl(): string{
    let lang = this.translator.activeLanguage.value === 'fr' ? '&culture=fr' : '';
    let url = this.signupUrl + '/login?createAccount=true' + lang;

    return url;
  }
}

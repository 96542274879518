import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslatorService } from 'src/app/_services/translator-service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {

  public salesEmail = 'sales@hexa.app';

  constructor(@Inject(DOCUMENT) private document: Document,public translator: TranslatorService ) {

  }

  ngOnInit(): void {
    this.document.body.classList.add('white');
  }

  ngOnDestroy(): void {
    // remove the class form body tag
    this.document.body.classList.remove('white');
  }

  setMultiChannelMobilePosition(): any {
    if (this.translator.activeLanguage.value === 'fr') {
      return {
        bottom: '1.875rem',
        position: 'relative'
      }
    }
  }
}

<app-top-bar></app-top-bar>
<div class="container-fluid p-0 fs-text">
  <div
    [hidden]="!this.showLightSpeed"
    id="hexa-lightspeed"
    class="lightspeed-section container-fluid p-0"
  >
    <div class="banner-section desktop-version row no-gutters">
      <div class="empty-container"></div>

      <div class="content-section col">
        <div class="header-section row no-gutters">
          <div class="hexa-logo">
            <img src="/assets/images/top-bar/hexa-logo-light.svg" />
          </div>

          <div class="plus-icon">
            <img src="/assets/images/lightspeed/plus-icon.png" />
          </div>

          <div class="lightspeed-logo">
            <img src="/assets/images/lightspeed/lightspeed-logo.svg" />
          </div>
        </div>

        <div class="text-section row no-gutters">
          <div class="lightspeed-text col">
            {{ this.translator.translate("lightspeed-banner-text") | async }}
          </div>
        </div>

        <div class="footer-section row no-gutters">
          <div class="footer-text col bold">
            hexa:
            {{ stripDot("ask") | async }}<span>.</span>
            {{ stripDot("listen") | async }}<span>.</span>
            {{ stripDot("take_action") | async }}<span>.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="banner-section mobile-version row no-gutters">
      <div class="content-section col">
        <div class="header-section row no-gutters">
          <div class="hexa-logo">
            <img src="/assets/images/top-bar/hexa-logo-light.svg" />
          </div>

          <div class="plus-icon">
            <img src="/assets/images/lightspeed/plus-icon.png" />
          </div>

          <div class="lightspeed-logo">
            <img src="/assets/images/lightspeed/lightspeed-logo.svg" />
          </div>
        </div>

        <div class="text-section row no-gutters">
          <div class="lightspeed-text col">
            {{ this.translator.translate("lightspeed-banner-text") | async }}
          </div>
        </div>

        <div class="footer-section row no-gutters">
          <div class="footer-text col bold">
            hexa:
            {{ stripDot("ask") | async }}<span>.</span>
            {{ stripDot("listen") | async }}<span>.</span>
            {{ stripDot("take_action") | async }}<span>.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="lightspeed-steps-section container-fluid">
      <div class="title-card step-card">
        <div class="header-card">
          <div
            [innerHTML]="
              this.translator.translate('lightspeed-steps-header')
                | async
                | safeHtml
            "
            class="header-text"
          ></div>
        </div>
      </div>

      <div class="step-one-card step-card">
        <div class="header">
          <div class="number">1</div>

          <div
            [innerHTML]="
              this.translator.translate('lightspeed-step-one')
                | async
                | safeHtml
            "
            class="text"
          ></div>
        </div>

        <div class="step-image">
          <img
            src="/assets/images/lightspeed/step-1-{{ this.activeLanguage }}.png"
          />
        </div>
      </div>

      <div class="step-two-card step-card">
        <div class="header">
          <div class="number">2</div>

          <div
            [innerHTML]="
              this.translator.translate('lightspeed-step-two')
                | async
                | safeHtml
            "
            class="text"
          ></div>
        </div>

        <div class="step-image">
          <img
            src="/assets/images/lightspeed/step-2-{{ this.activeLanguage }}.png"
          />
        </div>
      </div>

      <div class="step-three-card step-card">
        <div class="header">
          <div class="number">3</div>

          <div
            [innerHTML]="
              this.translator.translate('lightspeed-step-three')
                | async
                | safeHtml
            "
            class="text"
          ></div>
        </div>

        <div class="step-image">
          <img
            src="/assets/images/lightspeed/step-3-{{ this.activeLanguage }}.png"
          />
        </div>
      </div>

      <div class="step-four-card step-card">
        <div class="header">
          <div class="number">4</div>

          <div
            [innerHTML]="
              this.translator.translate('lightspeed-step-four')
                | async
                | safeHtml
            "
            class="text"
          ></div>
        </div>

        <div class="step-image">
          <img
            src="/assets/images/lightspeed/step-4-{{ this.activeLanguage }}.png"
          />
        </div>
      </div>

      <div class="step-five-card step-card">
        <div class="header">
          <div class="number">5</div>

          <div
            [innerHTML]="
              this.translator.translate('lightspeed-step-five')
                | async
                | safeHtml
            "
            class="text"
          ></div>
        </div>

        <div class="step-image">
          <img
            src="/assets/images/lightspeed/step-5-{{ this.activeLanguage }}.png"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="start-trial-section col col-12 text-center py-6">
      <div class="header fs-header-1 bold">
        {{ this.translator.translate("measure_customer_experience") | async }}
      </div>
      <div class="subheader fs-header-3">
        {{ this.translator.translate("improve_overall_satisfaction") | async }}
      </div>
      <a [routerLink]="['/startnow']" class="btn btn-dark mt-6 bold">{{
        this.translator.translate("start_free_trial") | async
      }}</a>
      <div class="annendum mt-3">
        {{ this.translator.translate("already_have_an_account") | async }}
        <a [href]="this.navigator.portalUrl" class="login">{{
          this.translator.translate("login") | async
        }}</a>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="feedback-section col col-12">
      <video
        class="w-100"
        src="/assets/images/home/hexasaas_{{ this.activeLanguage }}.mp4"
        poster="/assets/images/home/hexasaas_{{ this.activeLanguage }}.jpg"
        autoplay
        loop
        muted
        playsinline
        webkit-playsinline
      ></video>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="get-closer-section col col-12 text-center my-12">
      <img class="" src="assets/images/home/hexa-logo-dark.svg" />
      <h2 class="mt-4 fs-header-3">
        {{ this.translator.translate("closer_than_ever") | async }}
      </h2>
      <div class="steps">
        <div class="ask mx-1 mx-lg-2">
          <div class="number mx-auto">1</div>
          <div class="card">
            <h1 class="pt-6 semi-bold">
              {{ this.translator.translate("ask") | async }}
            </h1>
            <div
              class="px-5"
              [innerHTML]="this.translator.translate('ask_description') | async"
            ></div>
          </div>
        </div>
        <div class="listen mx-1 mx-lg-2">
          <div class="number mx-auto">2</div>
          <div class="card">
            <h1 class="pt-6 semi-bold">
              {{ this.translator.translate("listen") | async }}
            </h1>
            <div
              class="px-5"
              [innerHTML]="
                this.translator.translate('listen_description') | async
              "
            ></div>
          </div>
        </div>
        <div class="take-action mx-1 mx-lg-2">
          <div class="number mx-auto">3</div>
          <div class="card">
            <h1 class="pt-6 semi-bold">
              {{ this.translator.translate("take_action") | async }}
            </h1>
            <div
              class="px-5"
              [innerHTML]="
                this.translator.translate('take_action_description') | async
              "
            ></div>
          </div>
        </div>
      </div>
      <div>
        <a [routerLink]="['/startnow']" class="btn btn-primary mt-7 bold">{{
          this.translator.translate("start_free_trial") | async
        }}</a>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="works-for-you-section col col-12 py-14">
      <div class="container text-center">
        <div class="row">
          <h2
            class="col col-12 fs-header-2"
            [innerHTML]="this.translator.translate('works_for_you') | async"
          ></h2>
          <h3
            class="col col-12 fs-header-3"
            [innerHTML]="
              this.translator.translate('works_for_you_description') | async
            "
          ></h3>
        </div>
        <div class="row mt-10 has-separator">
          <div
            class="col col-12 col-md-6 text-center text-md-left pl-md-14 mt-md-9"
          >
            <h4 class="fs-header-4">
              {{ this.translator.translate("publish") | async }}
            </h4>
            <div class="mt-2">
              {{ this.translator.translate("publish_description") | async }}
            </div>
          </div>
          <div class="col col-12 col-md-6">
            <img
              src="/assets/images/home/publish_{{ this.activeLanguage }}.png"
              srcset="
                /assets/images/home/publish_{{this.activeLanguage}}@2x.png 2x,
                /assets/images/home/publish_{{this.activeLanguage}}@3x.png 3x
              "
            />
          </div>
        </div>
        <div class="row mt-3 has-separator">
          <div
            class="col col-12 col-md-6 order-sm-0 order-md-1 text-center text-md-left pl-md-14 mt-md-15"
          >
            <h4 class="fs-header-4">
              {{ this.translator.translate("analyze") | async }}
            </h4>
            <div class="mt-2">
              {{ this.translator.translate("analyze_description") | async }}
            </div>
          </div>
          <div class="col col-12 col-md-6 pl-md-7">
            <img
              src="/assets/images/home/analyze_{{ this.activeLanguage }}.png"
              srcset="
                /assets/images/home/analyze_{{this.activeLanguage}}@2x.png 2x,
                /assets/images/home/analyze_{{this.activeLanguage}}@3x.png 3x
              "
            />
          </div>
        </div>
        <div class="row mt-6 has-separator">
          <div
            class="col col-12 col-md-6 text-center text-md-left pl-md-14 mt-md-12"
          >
            <h4 class="fs-header-4">
              {{ this.translator.translate("benefits") | async }}
            </h4>
            <div class="mt-2">
              {{ this.translator.translate("benefits_description") | async }}
            </div>
          </div>
          <div class="col col-12 col-md-6">
            <img
              src="/assets/images/home/benefits_{{ this.activeLanguage }}.png"
              srcset="
                /assets/images/home/benefits_{{this.activeLanguage}}@2x.png 2x,
                /assets/images/home/benefits_{{this.activeLanguage}}@3x.png 3x
              "
            />
          </div>
        </div>
        <div class="row mt-9">
          <div
            class="col col-12 col-md-6 order-sm-0 order-md-1 text-center text-md-left pl-md-14 mt-md-9"
          >
            <h4 class="fs-header-4">
              {{ this.translator.translate("alerts") | async }}
            </h4>
            <div class="mt-2">
              {{ this.translator.translate("alerts_description") | async }}
            </div>
          </div>
          <div class="col col-12 col-md-6 pl-md-4">
            <img
              src="/assets/images/home/alerts_{{ this.activeLanguage }}.png"
              srcset="
                /assets/images/home/alerts_{{this.activeLanguage}}@2x.png 2x,
                /assets/images/home/alerts_{{this.activeLanguage}}@3x.png 3x
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row cost-section no-gutters text-center overflow-hidden">
    <div class="col col-12 col-md-6">
      <div class="container">
        <div class="row">
          <div class="col col-12 pt-7 text m-auto">
            <h1 class="bold fs-header-2">
              {{ this.translator.translate("how_much") | async }}
            </h1>
            <h3 class="mt-3 fs-header-3">
              {{ this.translator.translate("cost") | async }}
            </h3>
            <h3 class="bold fs-header-3">
              {{ this.translator.translate("free_beta") | async }}
            </h3>
            <div
              class="mt-3"
              [innerHtml]="
                this.translator.translate('unlimited_surveys_data_notification')
                  | async
              "
            ></div>
            <a [routerLink]="['/startnow']" class="btn btn-light mt-5 bold">{{
              this.translator.translate("start_free_trial") | async
            }}</a>
          </div>
          <div class="col col-12">
            <img
              src="/assets/images/home/cell-hand-wide.png"
              class="mt-4 cell-hand"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us-section col col-12 col-md-6">
      <div class="container">
        <div class="row">
          <div class="col col-12 pt-7 text m-auto">
            <div class="text mx-auto">
              <h1 class="bold fs-header-2">
                {{ this.translator.translate("need_more_power") | async }}
              </h1>
              <div
                class="mt-3 medium"
                [innerHtml]="
                  this.translator.translate('need_more_power_description')
                    | async
                "
              ></div>
              <a
                [href]="'mailto:' + this.salesEmail"
                class="btn btn-light mt-5 mt-md-7 bold"
                >{{ this.translator.translate("contact_us") | async }}</a
              >
            </div>
          </div>
          <div class="col col-12">
            <img
              src="/assets/images/home/need-more-power-lightning.png"
              srcset="
                /assets/images/home/need-more-power-lightning@2x.png 2x,
                /assets/images/home/need-more-power-lightning@3x.png 3x
              "
              class="my-4 lightning-hand"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters px-5 px-md-15">
    <div class="video-section col col-12 my-12">
      <iframe
        [src]="this.translator.translate('saas_video') | async | safeUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
<app-footer></app-footer>

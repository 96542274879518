<app-top-bar [title]=""></app-top-bar>

<div class="main-content container-fluid p-0">
  <div class="login-row">
    <div class="content">
      <div class="text-section">{{ this.translator.translate('already_have_an_account') | async }}</div>

      <a href="{{portalUrl}}" class="login-link">{{ this.translator.translate('login') | async }}</a>
    </div>
  </div>

  <div class="first-row row no-gutters">
    <div class="trial-section col">
      <div class="top-icons row no-gutters">
        <div class="diamond-icon col">
          <img src="assets/images/start-now/diamond-icon.png" />
        </div>

        <div class="line-segment-icon col">
          <img src="assets/images/start-now/line-segment-icon.png" />
        </div>
      </div>

      <div class="header-text">
        {{ this.translator.translate('listen_to_the_voice') | async }}
      </div>

      <div class="subheader-text">
        {{ this.translator.translate('sign_up') | async }}
      </div>

      <div class="section-btn row no-gutters">
        <div class="plus-icon col">
          <img src="assets/images/start-now/plus-icon.png" />
        </div>

        <div class="btn-section">
          <a href="{{this.getSignupUrl()}}" class="btn-trial col">{{ this.translator.translate('start_free_trial') | async }}</a>
        </div>

        <div class="circle-icon col">
          <img src="assets/images/start-now/circle-icon.png" />
        </div>
      </div>

      <div class="bottom-icon">
        <div class="plus-icon">
          <img src="assets/images/start-now/plus-icon.png" />
        </div>

        <div class="squiggly-line-icon">
          <img src="assets/images/start-now/squiggly-line-icon.png" />
        </div>
      </div>
    </div>
  </div>

  <div class="second-row container-fluid">
    <div class="content row no-gutters">
      <div class="left-col col">
        <div class="col-content">
          <div class="header-text">
            {{ this.translator.translate('already_on_hexa') | async }}
          </div>

          <div class="subheader-text">
            {{ this.translator.translate('register_corp') | async }}
          </div>

          <div class="btn-section">
            <a href="{{portalUrl}}" class="btn-register">
              {{ this.translator.translate('register_now') | async }}
            </a>
          </div>
        </div>
      </div>

      <div class="right-col col">
        <div class="col-content">
          <div class="header-text">{{ this.translator.translate('want_hexa_pro') | async }}</div>
          <div class="subheader-text">{{ this.translator.translate('large_organization') | async }} {{ this.translator.translate('big_or_specific') | async }}</div>
          <div class="btn-section">
            <a href="mailto:{{email}}" class="btn-contact">
              {{ this.translator.translate('contact_us') | async }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { TranslatorService } from '../_services/translator-service';

@Component({
  selector: 'app-lightspeed',
  templateUrl: './lightspeed.component.html',
  styleUrls: ['./lightspeed.component.scss']
})
export class LightspeedComponent implements OnInit {
  public salesEmail = 'sales@hexa.app';

  constructor(public translator: TranslatorService) { }

  ngOnInit(): void { }

  setMultiChannelMobilePosition(): any {
    if (this.translator.activeLanguage.value === 'fr') {
      return {
        bottom: '1.875rem',
        position: 'relative'
      }
    }
  }
}

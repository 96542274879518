import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { routes } from './routes';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { StartNowComponent } from './start-now/start-now.component';
import { ConfigService } from './_services/config.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './shared/footer/footer.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LightspeedComponent } from './lightspeed/lightspeed.component';
import { TranslatorService } from './_services/translator-service';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomMissingTranslationHelper } from './custom-missing-translation-helper';
import { SafeHtmlPipe } from './_directives/safe-html';
import { SafeUrlPipe } from './_directives/safe-url';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';

export function initialize(config: ConfigService): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      config.init.then(() => {
        resolve(true);
      });
    });
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TopBarComponent,
    StartNowComponent,
    FooterComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    LightspeedComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    TermsOfUseComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
    HttpClientModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-KZJDQJG',
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHelper,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      multi: true,
      deps: [ConfigService],
    },
    ConfigService,
    TranslateService,
    TranslatorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

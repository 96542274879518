import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { StartNowComponent } from './start-now/start-now.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'lightspeed',
    component: HomeComponent,
  },
  {
    path: 'startnow',
    component: StartNowComponent,
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'patrons-privacy-policy',
    component: TermsOfUseComponent,
  },
];
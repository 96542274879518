import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/_services/config.service';
import { TranslatorService } from './translator-service';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  constructor(
    private translator: TranslatorService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {}

  get portalUrl(): string {
    var lang =
      this.translator.activeLanguage.value == 'fr' ? '&culture=fr' : '';
    var returnUrl =
      'returnUrl=' +
      encodeURIComponent(this.configService.config.portal.baseUrl);
    var url =
      this.configService.config.identity.baseUrl +
      '/Account/Login?' +
      returnUrl +
      lang;

    return url;
  }

  get signupUrl(): string {
    let lang =
      this.translator.activeLanguage.value === 'fr' ? '&culture=fr' : '';
    let url =
      this.configService.config.signup.baseUrl +
      '/login?createAccount=true' +
      lang;

    return url;
  }
}

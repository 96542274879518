import { Component, OnInit } from '@angular/core';
import { NavigatorService } from 'src/app/_services/navigator.service';
import { TranslatorService } from 'src/app/_services/translator-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public year = (new Date()).getFullYear();
  public email = 'support@hexa.app';

  constructor(public navigator: NavigatorService, public translator: TranslatorService) {
  }

  ngOnInit(): void {
  }

}

<div class="top-bar">
  <div class="logo-section ml-2 ml-md-11">
    <a routerLink="/"
      ><img class="logo" src="assets/images/top-bar/hexa-logo-light.svg"
    /></a>
  </div>

  <div class="header-text medium" *ngIf="title">{{ title }}</div>

  <div class="right-side mr-1 mr-md-11">
    <div class="links-section mr-3 mr-md-7" *ngIf="showRight">
      <div class="btn btn-info top-bar-btn" (click)="goToPortal()">
        {{ this.translator.translate("login_short") | async }}
      </div>
    </div>
    <div class="language-select">
      <div
        class="lang lang-en"
        [ngClass]="{ active: selectedLanguage === 'en' }"
        (click)="toggleLanguage('en')"
      >
        EN
      </div>
      <div class="divider"></div>
      <div
        class="lang lang-fr"
        [ngClass]="{ active: selectedLanguage === 'fr' }"
        (click)="toggleLanguage('fr')"
      >
        FR
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/_services/config.service';
import { BehaviorSubject, Subscription } from 'rxjs';
//import * as moment from 'moment';
import { ValueObserver } from '../_base/value.observer';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  private readonly loggerFrom: string;
  private readonly storageName = 'hexa-lang';
  private readonly supportedCultures = ['fr', 'en'];

  public useSubscription: Subscription;
  public translations: Promise<string>[];
  public readonly activeLanguage: ValueObserver<string> = new ValueObserver<string>();

  constructor(private translateService: TranslateService, private configService: ConfigService, private route: ActivatedRoute) {
    this.translations = [];

    let initialLang = this.configService.config.website.defaultLanguage;

    if (localStorage.getItem(this.storageName)) {
      initialLang = localStorage.getItem(this.storageName);
    } else {
      initialLang = this.getUserLanguage();
    }

    this.changeLanguage(initialLang);

    this.route.queryParams.subscribe(params => {
      if (params.culture) {
        this.changeLanguage(params.culture);
      }
      else if (params.lang) {
        this.changeLanguage(params.lang);
      }
    });
  }

  private getUserLanguage(): string {
    for (const language of navigator.languages) {
      const lang = language.split('-')[0];

      if (this.supportedCultures.indexOf(lang) > -1) {
        return lang;
      }
    }

    return this.configService.config.website.defaultLanguage;
  }

  public translate(key: string): Promise<string> {
    const keyLang = `${key}_${this.activeLanguage.value}`;

    if (this.translations[keyLang]) {
      return this.translations[keyLang];
    }

    if (key === null || key === '') {
      return new Promise(resolve => resolve);
    }

    return this.translateService
      .get(key)
      .toPromise()
      .then(result => {
        this.translations[keyLang] = Promise.resolve(result);
        return this.translations[keyLang];
      });
  }

  public setDefaultLanguage(langCode: string): void {
    this.translateService.setDefaultLang(langCode);
  }

  public changeLanguage(langCode: string): void {
    if (this.useSubscription) {
      this.useSubscription.unsubscribe();
    }

    this.useSubscription = this.translateService.use(langCode).subscribe(lang => {
      if (this.supportedCultures.indexOf(langCode) < 0) {
        langCode = this.configService.config.website.defaultLanguage;
      }

      this.translateService.currentLang = langCode;
      this.activeLanguage.value = langCode;

      localStorage.setItem(this.storageName, langCode);

      //If we ever need moment uncomment
      //if (langCode === 'fr') {
      //  moment.locale('fr-ca');
      //} else {
      //  moment.locale(langCode);
      //}

      //this.portalEvents.language.value = langCode;
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/_services/config.service';
import { TranslatorService } from 'src/app/_services/translator-service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  public selectedLanguage = 'en';
  public portalUrl = '';


  @Input() title = '';
  @Input() showRight = true;
  constructor(private configService: ConfigService, public translator: TranslatorService) {
    this.translator.activeLanguage.listen(lang => {
      this.selectedLanguage = lang;
      this.setUrl();
    });
  }

  ngOnInit(): void {
   this.setUrl();
  }

  setUrl(){
    var lang = this.selectedLanguage == 'fr' ? '&culture=fr' : '';
    var returnUrl = 'returnUrl=' + encodeURIComponent(this.configService.config.portal.baseUrl);
    var url = this.configService.config.identity.baseUrl + '/Account/Login?' + returnUrl + lang;

    this.portalUrl = url;
  }

  toggleLanguage(lang: string): void {
    this.translator.changeLanguage(lang);
  }

  goToPortal() {
    document.location.href = this.portalUrl;
  }
}

import { BehaviorSubject, Observable, Subject, throwError, Subscription, race } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

export class ValueObserver<T> {
  protected callAfterSet: () => void;
  constructor(callback?: () => void) {
    this.callAfterSet = callback;

    this.valueChangedSubject = new Subject<T>();
    this.valueBehavior = new BehaviorSubject<T>(undefined);
    this.valueBehavior.subscribe(v => {
      if (this.isDefined) {
        this.valueChangedSubject.next(v);
      }
    });

    this.valueChanged = this.valueChangedSubject.asObservable();
  }

  protected valueBehavior: BehaviorSubject<T>;
  protected valueChangedSubject: Subject<T>;

  private valueChanged: Observable<T>;

  public listen(f: (value: T) => void, cancellationToken?: Subject<void> | Subject<unknown> | (Subject<void> | Subject<unknown>)[], errorHandler?: (error: any) => void): Subscription {
    if (this.isDefined) {
      f(this.valueBehavior.value);
    }

    if (errorHandler) {
      if (cancellationToken) {
        return this.valueChanged
          .pipe(
            takeUntil(race(cancellationToken)),
            catchError(error => throwError(error))
          )
          .subscribe(
            v => f(v),
            error => errorHandler(error)
          );
      } else {
        return this.valueChanged.pipe(catchError(error => throwError(error))).subscribe(
          v => f(v),
          error => errorHandler(error)
        );
      }
    } else {
      if (cancellationToken) {
        return this.valueChanged.pipe(takeUntil(race(cancellationToken))).subscribe(v => f(v));
      } else {
        return this.valueChanged.subscribe(v => f(v));
      }
    }
  }

  public unset() {
    this.valueBehavior.next(undefined);
  }

  private get isDefined(): boolean {
    return this.valueBehavior.value !== undefined;
  }

  get value(): T {
    return this.valueBehavior.value;
  }

  set value(value: T) {
    if (value !== undefined && value !== this.valueBehavior.value) {
      this.valueBehavior.next(value);

      if (this.callAfterSet) {
        this.callAfterSet();
      }
    }
  }
}

<app-top-bar></app-top-bar>

<div class="main-content container-fluid">
  <div class="first-row row">
    <div class="left-col col">
      <div class="banner-section">
        <div class="header">{{ this.translator.translate('improve_customer_satisfaction') | async }}</div>

        <div class="subheader">{{ this.translator.translate('listen_to_the_voice') | async }}</div>
      </div>

      <div class="text-section container-fluid">
        <div class="text-container row">
          <div class="text col">
            <strong>hexa</strong> {{ this.translator.translate('higher_quality_actionable_feedback') | async }}
            <br /><br />
            <strong>hexa</strong> {{ this.translator.translate('better_decisions') | async }}
          </div>
        </div>
      </div>

      <div class="btn-section container-fluid">
        <div class="btn-container row">
          <div routerLink="/startnow" class="btn-do-this">{{ this.translator.translate('lets_do_this') | async }}</div>
        </div>
      </div>
    </div>

    <div class="right-col col">
      <div class="video-section">
        <video src="/assets/images/home-page/hexa-saas.mp4" poster="/assets/images/home-page/hexa-saas-poster.jpg" autoplay loop muted playsinline></video>
      </div>
    </div>
  </div>

  <div class="video-row row">
    <div class="embed-container">
      <iframe [src]="this.translator.translate('saas_video') | async | safeUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>

  <div id="hexa-lightspeed" class="lightspeed-section container-fluid">
    <div class="banner-section desktop-version row">
      <div class="empty-container"></div>

      <div class="content-section col">
        <div class="header-section row">
          <div class="hexa-logo">
            <img src="/assets/images/top-bar/hexa-logo.svg" />
          </div>

          <div class="plus-icon">
            <img src="/assets/images/lightspeed/plus-icon.png" />
          </div>

          <div class="lightspeed-logo">
            <img src="/assets/images/lightspeed/lightspeed-logo.svg" />
          </div>
        </div>

        <div class="text-section row">
          <div class="lightspeed-text col">
            {{ this.translator.translate('lightspeed-banner-text') | async }}
          </div>
        </div>

        <div class="footer-section row">
          <div class="footer-text col">
            hexa: {{ this.translator.translate('lightspeed-ask') | async }}<span>.</span> {{ this.translator.translate('lightspeed-listen') | async }}<span>.</span> {{ this.translator.translate('lightspeed-take-action') | async }}<span>.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="banner-section mobile-version row">
      <div class="content-section col">
        <div class="header-section row">
          <div class="hexa-logo">
            <img src="/assets/images/top-bar/hexa-logo.svg" />
          </div>

          <div class="plus-icon">
            <img src="/assets/images/lightspeed/plus-icon.png" />
          </div>

          <div class="lightspeed-logo">
            <img src="/assets/images/lightspeed/lightspeed-logo.svg" />
          </div>
        </div>

        <div class="text-section row">
          <div class="lightspeed-text col">
            {{ this.translator.translate('lightspeed-banner-text') | async }}
          </div>
        </div>

        <div class="footer-section row">
          <div class="footer-text col">
            hexa: {{ this.translator.translate('lightspeed-ask') | async }}<span>.</span> {{ this.translator.translate('lightspeed-listen') | async }}<span>.</span> {{ this.translator.translate('lightspeed-take-action') | async }}<span>.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="lightspeed-steps-section container-fluid">
      <div class="title-card step-card">
        <div class="header-card">
          <div [innerHTML]="this.translator.translate('lightspeed-steps-header') | async | safeHtml" class="header-text"></div>
        </div>
      </div>

      <div class="step-one-card step-card">
        <div class="header">
          <div class="number">1</div>

          <div [innerHTML]="this.translator.translate('lightspeed-step-one') | async | safeHtml" class="text"></div>
        </div>

        <div class="step-image">
          <img src="/assets/images/lightspeed/step-1-{{this.translator.activeLanguage.value}}.png" />
        </div>
      </div>

      <div class="step-two-card step-card">
        <div class="header">
          <div class="number">2</div>

          <div [innerHTML]="this.translator.translate('lightspeed-step-two') | async | safeHtml" class="text"></div>
        </div>

        <div class="step-image">
          <img src="/assets/images/lightspeed/step-2-{{this.translator.activeLanguage.value}}.png" />
        </div>
      </div>

      <div class="step-three-card step-card">
        <div class="header">
          <div class="number">3</div>

          <div [innerHTML]="this.translator.translate('lightspeed-step-three') | async | safeHtml" class="text"></div>
        </div>

        <div class="step-image">
          <img src="/assets/images/lightspeed/step-3-{{this.translator.activeLanguage.value}}.png" />
        </div>
      </div>

      <div class="step-four-card step-card">
        <div class="header">
          <div class="number">4</div>

          <div [innerHTML]="this.translator.translate('lightspeed-step-four') | async | safeHtml" class="text"></div>
        </div>

        <div class="step-image">
          <img src="/assets/images/lightspeed/step-4-{{this.translator.activeLanguage.value}}.png" />
        </div>
      </div>

      <div class="step-five-card step-card">
        <div class="header">
          <div class="number">5</div>

          <div [innerHTML]="this.translator.translate('lightspeed-step-five') | async | safeHtml" class="text"></div>
        </div>

        <div class="step-image">
          <img src="/assets/images/lightspeed/step-5-{{this.translator.activeLanguage.value}}.png" />
        </div>
      </div>
    </div>
  </div>

  <div class="second-row row">
    <div class="left-col col">
      <div class="text-container">
        <div class="header">
          <strong>hexa</strong><br />{{ this.translator.translate('works_for_you') | async }}
        </div>

        <div class="subheader">
          {{ this.translator.translate('understand_the_voc') | async }}
        </div>
      </div>
    </div>

    <div class="right-col col">
      <img src="assets/images/home-page/fraser-cottrell.png" />
    </div>
  </div>

  <div class="third-row row">
    <div class="left-col col">
      <img src="assets/images/home-page/iStock-1225139952.png" />
    </div>

    <div class="right-col col">
      <div class="text-container">
        {{ this.translator.translate('notified_in_real_time') | async }}
      </div>
    </div>
  </div>

  <div class="fourth-row row">
    <div class="left-col col">
      <div class="text-container">{{ this.translator.translate('patrons_into_loyal_customers') | async }}</div>
    </div>

    <div class="right-col col">
      <img src="assets/images/home-page/abillionveg-7E28zXFon7o-unsplash.png" />
    </div>
  </div>

  <div class="fifth-row row">
    <div class="left-col col">
      <video src="/assets/images/home-page/dont-go.mp4" autoplay loop muted playsinline></video>
    </div>

    <div class="right-col col">
      <div class="text-container">
        <div class="header">{{ this.translator.translate('no_commitment') | async }} <br />{{ this.translator.translate('cancel_anytime') | async }} </div>

        <div class="subheader">{{ this.translator.translate('prefer_you_wouldnt') | async }}</div>
      </div>
    </div>
  </div>

  <div class="need-power-banner container-fluid">
    <div class="text-section container-fluid">
      <div class="position-relative">
        <div class="lightning">
          <img src="/assets/images/home-page/need-more-power-lightning.png" alt="lightning"/>
        </div>

        <div class="header row">
          <div class="header-text">{{ this.translator.translate('need_more_power') | async }}</div>
        </div>

        <div class="subheader row">
          <div class="subheader-text">{{ this.translator.translate('large_organization') | async }}
            <br />
            <strong>hexa</strong> {{ this.translator.translate('big_or_specific') | async }}
          </div>
        </div>

        <div class="btn-section row">
          <div class="btn-customize">
            <a href="mailto:{{salesEmail}}">{{ this.translator.translate('lets_customize') | async }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="timeline-section container-fluid">
    <div class="header-section">
      <div class="hexa-logo-container row">
        <img src="assets/images/home-page/hexa-logo-dark.svg" />
      </div>

      <div class="header-text row">{{ this.translator.translate('closer_than_ever') | async }}</div>
    </div>

    <div class="steps-timeline row desktop-version">
      <div class="left-col col">
        <div class="multi-channel step">
          <div class="icon">
            <img src="assets/images/home-page/step-2-icon.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('multi_channel') | async }}</div>

            <div class="line-icon">
              <img src="assets/images/home-page/timeline-line.png" />
            </div>

            <div class="subtext">{{ this.translator.translate('multi_channel_info') | async }}</div>
          </div>
        </div>

        <div class="artificial-intelligence step">
          <div class="icon">
            <img src="assets/images/home-page/step-4-icon.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('artificial_intelligence') | async }}</div>

            <div class="line-icon">
              <img src="assets/images/home-page/timeline-line.png" />
            </div>

            <div class="subtext">{{ this.translator.translate('artificial_intelligence_info') | async }}</div>
          </div>
        </div>
      </div>

      <div class="timeline-image col">
        <img src="assets/images/home-page/steps-desktop.png" />
      </div>

      <div class="right-col col">
        <div class="automated-survey step">
          <div class="icon">
            <img src="assets/images/home-page/step-1-icon.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('automated_survey') | async }}</div>

            <div class="line-icon">
              <img src="assets/images/home-page/timeline-line.png" />
            </div>

            <div class="subtext">{{ this.translator.translate('automated_survey_info') | async }}</div>
          </div>
        </div>

        <div class="dashboard-analytics step">
          <div class="icon">
            <img src="assets/images/home-page/step-3-icon.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('dashboard_analytics') | async }}</div>

            <div class="line-icon">
              <img src="assets/images/home-page/timeline-line.png" />
            </div>

            <div class="subtext">{{ this.translator.translate('dashboard_analytics_info') | async }}</div>
          </div>
        </div>

        <div class="real-time step">
          <div class="icon">
            <img src="assets/images/home-page/step-5-icon.png" />
          </div>

          <div class="text-section">
            <div class="header"> {{ this.translator.translate('real_time_notifications') | async }} </div>

            <div class="line-icon">
              <img src="assets/images/home-page/timeline-line.png" />
            </div>

            <div class="subtext"> {{ this.translator.translate('real_time_notifications_info') | async }} </div>
          </div>
        </div>
      </div>
    </div>

    <div class="steps-timeline row mobile-version">
      <div class="left-col col">
        <div class="timeline-mobile-icon">
          <img src="assets/images/home-page/steps-mobile.png" />
        </div>
      </div>

      <div class="right-col col">
        <div class="automated-survey step">
          <div class="icon">
            <img src="assets/images/home-page/step-1-icon-mobile.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('automated_survey') | async }} </div>

            <div class="subtext">{{ this.translator.translate('automated_survey_info') | async }} </div>
          </div>
        </div>

        <div class="multi-channel step" [style]="setMultiChannelMobilePosition()">
          <div class="icon">
            <img src="assets/images/home-page/step-2-icon-mobile.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('multi_channel') | async }}</div>

            <div class="subtext">{{ this.translator.translate('multi_channel_info') | async }}.</div>
          </div>
        </div>

        <div class="dashboard-analytics step" [style]="{ top: this.translator.activeLanguage.value === 'fr' ? '0rem' : '2rem' }">
          <div class="icon">
            <img src="assets/images/home-page/step-3-icon-mobile.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('dashboard_analytics') | async }}</div>

            <div class="subtext">{{ this.translator.translate('dashboard_analytics_info') | async }}</div>
          </div>
        </div>

        <div class="artificial-intelligence step" [style]="{ top: this.translator.activeLanguage.value === 'fr' ? '1rem' : '4rem' }">
          <div class="icon">
            <img src="assets/images/home-page/step-4-icon-mobile.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('artificial_intelligence') | async }}</div>

            <div class="subtext">{{ this.translator.translate('artificial_intelligence_info') | async }}</div>
          </div>
        </div>

        <div class="real-time step" [style]="{ top: this.translator.activeLanguage.value === 'fr' ? '2rem' : '8rem' }">
          <div class="icon">
            <img src="assets/images/home-page/step-5-icon-mobile.png" />
          </div>

          <div class="text-section">
            <div class="header">{{ this.translator.translate('real_time_notifications') | async }}</div>

            <div class="subtext">{{ this.translator.translate('real_time_notifications_info') | async }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-section row">
      <div class="text-container col">
        {{ this.translator.translate('no_tech') | async }}<br />{{ this.translator.translate('safe_and_secure') | async }}<br />{{ this.translator.translate('multi_language') | async }}
      </div>
    </div>
  </div>

  <div class="hook-me-up-section row">
    <div class="left-col col">
      <div class="text-container">{{ this.translator.translate('proven_methodology') | async }}<br />{{ this.translator.translate('years_experience') | async }}<br />{{ this.translator.translate('get_started') | async }}</div>

      <div class="btn-section">
        <div routerLink="/startnow" class="btn-hook-me-up">
          {{ this.translator.translate('hook_me_up') | async }}
        </div>
      </div>
    </div>

    <div class="right-col col">
      <img src="assets/images/home-page/xps-4TBSG2Oqu0Q-unsplash-1.png" />
    </div>
  </div>

  <div class="how-much-section container-fluid">
    <div class="arrow-green">
      <img src="assets/images/home-page/arrow-green.png" class="desktop-icon" />
      <img src="assets/images/home-page/arrow-green-mobile.png" class="mobile-icon" />
    </div>

    <div class="header row">
      <div class="header-text col">{{ this.translator.translate('how_much') | async }}</div>
    </div>

    <div class="subheader row">
      <div class="subheader-text col">{{ this.translator.translate('free_beta') | async }}</div>
    </div>

    <div class="price row">
      <div class="price-text col">{{ this.translator.translate('price_per_period') | async }} </div>
    </div>

    <div class="arrow-blue">
      <img src="assets/images/home-page/arrow-blue.png" class="desktop-icon" />
      <img src="assets/images/home-page/arrow-blue-mobile.png" class="mobile-icon" />
    </div>

    <div class="text-section row">
      <div class="text col" [innerHTML]="this.translator.translate('unlimited_surveys_data_notification') | async | safeHtml">
      </div>
    </div>

    <div class="arrow-red">
      <img src="assets/images/home-page/arrow-red.png" class="desktop-icon" />
      <img src="assets/images/home-page/arrow-red-mobile.png" class="mobile-icon" />
    </div>

    <div class="btn-section row">
      <div routerLink="/startnow" class="btn-freebies">{{ this.translator.translate('ready_freebie') | async }}</div>
    </div>
  </div>

  <div class="footer-banner row">
    <div class="left-col col">
      <img src="assets/images/home-page/iStock-614640636.png" />
    </div>

    <div class="right-col col">
      <div class="header-text">{{ this.translator.translate('ask_listen_take_action') | async }}</div>

      <div class="text-container">
        <strong>hexa</strong> {{ this.translator.translate('works_for_you') | async }}
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

<div class="footer-row container-fluid">
  <div class="row">
    <div class="d-none d-lg-block col-lg-1"></div>
    <div class="col col-12 col-md-2 col-lg-2 mt-4">
      <a routerLink="/"
        ><img class="logo" src="assets/images/top-bar/hexa-logo-light.svg"
      /></a>
    </div>
    <div
      class="options medium col col-12 col-md-4 col-lg-3 mt-6 mb-md-3 mt-md-5"
    >
      <a class="mb-3" [href]="this.navigator.signupUrl">{{
        this.translator.translate("signup") | async
      }}</a>
      <a
        class="mb-3"
        [href]="this.translator.translate('support_link') | async | safeUrl"
        >{{ this.translator.translate("support_center") | async }}</a
      >
      <div class="w-100"></div>
      <a class="mb-3" [href]="this.navigator.portalUrl">{{
        this.translator.translate("login_short") | async
      }}</a
      ><a
        class="mb-3"
        [routerLink]="['/lightspeed']"
        [fragment]="'hexa-lightspeed'"
      >
        {{ this.translator.translate("Lightspeed") | async }}</a
      >
      <div class="w-100 separator"></div>
    </div>
    <div class="terms col col-12 col-md-3 col-lg-3 mb-md-3 mt-5">
      <div class="m-md-auto">
        <a routerLink="/patrons-privacy-policy">{{
          this.translator.translate("terms-of-use") | async
        }}</a>
        <div class="w-100"></div>
        <a class="mt-3" routerLink="/terms-of-service">{{
          this.translator.translate("terms_of_service") | async
        }}</a>
        <div class="w-100"></div>
        <a class="mt-3" routerLink="/privacy-policy">{{
          this.translator.translate("privacy_policy") | async
        }}</a>
      </div>
    </div>
    <div class="support col col-12 col-md-3 col-lg-3 mt-4 mt-md-5">
      <div class="pl-lg-6 pl-xl-12">
        <div class="support-link">
          {{ this.translator.translate("questions_inquiries") | async }}
          <a [href]="'mailto:' + email">{{ email }}</a>
        </div>

        <div class="copyright-text mt-4 mb-2 mt-md-10">
          © {{ year }} LANLA.
          {{ this.translator.translate("all_rights") | async }}
        </div>
      </div>
    </div>
  </div>
</div>
